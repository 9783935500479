import { Box, Stack, Typography } from "@mui/material"
import Loading from "components/Loading/Loading"
import Button from "components/ui/Button/Button"
import ChainStack from "components/ui/ChainStack/ChainStack"
import ContextMenu from "components/ui/ContextMenu"
import FontIcon from "components/ui/FontIcon/FontIcon"
import { useAppDispatch, useAppSelector } from "config/store"
import useActions from "hooks/useActions"
import NodeType from "models/node.model"
import { useEffect, useMemo } from "react"
import { setLocalSearchDisabled, setLocalSearchValue } from "reducers/localSearch"
//import sortNodes from "utils/sortNodes"
import { useSort } from "hooks/useSort"
import { OnSelectType } from "view/types/node"
import EntitiesTable from "../EntitiesTable/EntitiesTable"
import Node from "../Node"
import { EntitesLayout } from "../types"

interface EntitiesListProps {
  parent: NodeType
  contextHeader?: string[]
  contextItems: string[]
  enablePreview?: boolean
  onClick: () => void
  onNodesSelect: OnSelectType
  selectedOne?: NodeType
  //: (id: string) => NodeType
}

const EntitiesList = (props: EntitiesListProps) => {

  const {
    parent,
    contextHeader,
    contextItems,
    enablePreview,
    onClick,
    onNodesSelect,
    selectedOne,
  } = props

  const { children = {} } = parent as NodeType
  const { content: entities } = children as NodeType['children']

  const { value: searchValue } = useAppSelector(state => state.localSearch)
  const selected = useAppSelector(state => state.clipboard?.selected)
  const layout = useAppSelector(state => state.toolbar.layout)
  //const sortMode = useAppSelector(state => state.toolbar.sortMode)

  const sortNodes = useSort()

  const dispatch = useAppDispatch()
  const { renderHeader, renderItems } = useActions(parent)

  const filteredEntities = useMemo(() => {
    if (!searchValue || searchValue === '') return entities
    return entities?.filter((entity: NodeType) => entity.name.toLowerCase().includes(searchValue.toLowerCase()))
  }, [entities, searchValue])

  const handleClearSearch = () =>
    dispatch(setLocalSearchValue(''))

  useEffect(() => {
    if (entities?.length > 0) {
      dispatch(setLocalSearchDisabled(false))
    }
    else {
      dispatch(setLocalSearchDisabled(true))
    }
    return () => {
      dispatch(setLocalSearchValue(''))
    }
  }, [entities])

  const nodes = useMemo(() => filteredEntities?.length > 0 ? sortNodes(filteredEntities) : [], [filteredEntities, sortNodes])

  return (
    <Loading showLoading={!nodes}>
      <ContextMenu
        contextId={`entities-${parent?.id}`}
        disabled={!parent?.id || layout === EntitesLayout.TABLE}
        header={renderHeader(contextHeader)}
        items={renderItems(contextItems)}
      >
        <Stack id='entities-list-wrapper' overflow='auto'
          sx={{
            display: 'flex',
            alignItems: 'flex-start',
            height: '100%'
          }}
          onClick={onClick}
        >
          {searchValue && (
            <ChainStack
              spacing='8px'
              padding='20px 0 0 20px'
            >
              <Typography variant='h6'>Результаты поиска «{searchValue}»</Typography>
              <Button color='primary' onClick={handleClearSearch}>
                <FontIcon icon='close' color='primary' />
              </Button>
            </ChainStack>
          )}
          {searchValue?.length > 0 && nodes?.length < 1 && entities?.length > 0 && (
            <Typography padding='0 20px' color='text.disabled' variant='h6'>
              Ничего не найдено, попробуйте очистить поле для поиска
            </Typography>
          )}
          {entities?.length < 1 && (
            <Typography padding='20px 20px' color='text.disabled' variant='h6'>
              Здесь ничего нет. Вы можете создать вложенную папку или добавить документы
            </Typography>
          )}
          {layout === EntitesLayout.GRID
            ? (
              <Box component='ul'
                sx={{
                  display: 'flex',
                  overflow: 'auto',
                  flexWrap: 'wrap',
                  padding: '20px 1px 20px 1px',
                  gap: '10px',
                  listStyle: 'none',
                  margin: 0,
                }}
              >
                {nodes?.map((item: NodeType) => {
                  const isSelected = Boolean(selected?.find(x => x.id === item.id))
                  return (
                    <Node
                      component='li'
                      key={item.id}
                      node={item}
                      selected={isSelected}
                      onSelect={onNodesSelect}
                    />
                  )
                })}
              </Box>
            )
            : nodes?.length > 0 && (
              <EntitiesTable
                entities={nodes}
                contextItems={contextItems}
                enablePreview={enablePreview}
                onNodesSelect={onNodesSelect}
              />
            )
          }
        </Stack>
      </ContextMenu>
    </Loading>
  )
}

export default EntitiesList
