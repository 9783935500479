import { commonApi } from "config/common-api"
import { API_URL } from "config/constants"
import { EmployeeManyType, EmployeeType } from "models/employee.model"
import NodeType from "models/node.model"
import { extraNames } from "./utils"

const apiUrl = `${API_URL}/kbl/employee`

export const node = commonApi.injectEndpoints({
  endpoints: builder => ({

    getMe: builder.query<EmployeeType, void>({
      query: () => ({
        url: `${apiUrl}/me`,
      }),
      providesTags: ['USER']
    }),

    getEmployee: builder.query<EmployeeType, { id: string }>({
      query: ({ id }) => ({
        url: `${apiUrl}/${id}`,
      }),
      providesTags: ['EMPLOYEES']
    }),

    getFilteredEmployee: builder.mutation<EmployeeManyType, any>({
      query: (body) => ({
        method: 'POST',
        url: `${apiUrl}/filtering?size=500`,
        body,
      }),
      transformResponse: (result: EmployeeManyType) => ({
        ...result, content: result.content.map((item: EmployeeType) => ({
          ...item,
          ...extraNames(item)
        }))
      }),
      invalidatesTags:['EMPLOYEES']
    }),

    getHomepage: builder.query<any, void>({
      query: () => ({
        url: `${apiUrl}/homepage`,
      }),
      providesTags: ['HOMEPAGE']
    }),

    setHomepage: builder.mutation<any, { nodeId: string }>({
      query: ({ nodeId }) => ({
        url: `${apiUrl}/homepage`,
        method: 'POST',
        body: { id: nodeId }
      }),
      invalidatesTags: (res) => ['TRENDS', 'HOMEPAGE', 'NODE', 'FAVORITES']
    }),

    getFavorites: builder.query<NodeType[], void>({
      query: () => ({
        url: `${apiUrl}/favorites`,
      }),
      providesTags: ['FAVORITES']
    }),

    setFavorite: builder.mutation<any, { nodeId: string }>({
      query: ({ nodeId }) => ({
        url: `${apiUrl}/favorites`,
        method: 'POST',
        body: { id: nodeId }
      }),
      invalidatesTags: (res) => ['TRENDS', 'FAVORITES', 'NODE']
    }),

    removeFavorite: builder.mutation<any, { nodeId: string }>({
      query: ({ nodeId }) => ({
        url: `${apiUrl}/favorites/remove`,
        method: 'POST',
        body: { id: nodeId }
      }),
      invalidatesTags: (res) => ['TRENDS', 'FAVORITES', 'NODE']
    }),

    getQuickNodes: builder.query<NodeType[], { nodeId: string }>({
      query: ({ nodeId }) => ({
        url: `${apiUrl}/quick-node/${nodeId}`,
      }),
      providesTags: ['QUICK_NODES']
    }),

    setQuickNode: builder.mutation<any, { nodeId: string }>({
      query: ({ nodeId }) => ({
        url: `${apiUrl}/quick-node`,
        method: 'POST',
        body: { id: nodeId }
      }),
      invalidatesTags: (res) => ['TRENDS', 'QUICK_NODES', 'NODE', 'FAVORITES']
    }),

    removeQuickNode: builder.mutation<any, { nodeId: string }>({
      query: ({ nodeId }) => ({
        url: `${apiUrl}/quick-node/remove`,
        method: 'POST',
        body: { id: nodeId }
      }),
      invalidatesTags: (res) => ['TRENDS', 'QUICK_NODES', 'NODE', 'FAVORITES']
    }),

    getTrends: builder.query<NodeType[], void>({
      query: () => ({
        url: `${apiUrl}/trends`,
      }),
      providesTags: ['TRENDS']
    }),
  })
})

export const {
  useGetMeQuery,
  useGetFilteredEmployeeMutation,
  useGetEmployeeQuery,
  useGetHomepageQuery,
  useGetFavoritesQuery,
  useGetQuickNodesQuery,
  useSetHomepageMutation,
  useSetFavoriteMutation,
  useRemoveFavoriteMutation,
  useSetQuickNodeMutation,
  useRemoveQuickNodeMutation,
  useGetTrendsQuery
} = node
