import { SvgIconProps } from "@mui/material"
import { defaultIcon } from "./default"
import { defaultSmallIcon } from "./default_s"
import { docIcon } from "./doc"
import { docSmallIcon } from "./doc_s"
import { pdfIcon } from "./pdf"
import { pdfSmallIcon } from "./pdf_s"
import { pngIcon } from "./png"
import { pngSmallIcon } from "./png_s"
import { pptIcon } from "./ppt"
import { pptSmallIcon } from "./ppt_s"
import { xlsIcon } from "./xls"
import { xlsSmallIcon } from "./xls_s"

interface DocumentIconProps extends SvgIconProps {
  small?: boolean
  extension?: string
}

export default function DocumentIcon({ sx, small, extension, ...rest }: DocumentIconProps) {
  switch (extension?.toLowerCase()) {
    case 'pdf':
      return small ? pdfSmallIcon : pdfIcon
    case 'doc':
    case 'docx':
    case 'rtf':
      return small ? docSmallIcon : docIcon
    case 'xls':
    case 'xlsx':
      return small ? xlsSmallIcon : xlsIcon
    case 'ppt':
    case 'ppts':
      return small ? pptSmallIcon : pptIcon
    case 'png':
      return small ? pngSmallIcon : pngIcon
    default:
      return small ? defaultSmallIcon : defaultIcon
  }
}
