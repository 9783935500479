import { Stack, Typography } from '@mui/material'
import { FetchBaseQueryError } from '@reduxjs/toolkit/query'
import { PASTE_HEADER } from 'config/constants'
import { useAppDispatch, useAppSelector } from 'config/store'
import useClipboard from 'hooks/useClipboard'
import useConfirm from 'hooks/useConfirm'
import usePath from 'hooks/usePath'
import NodeType from 'models/node.model'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Route, Routes } from 'react-router'
import { useGetFavoritesQuery } from 'reducers/api/employee.api'
import { useGetDocumentNodeQuery, useGetNodeQuery } from 'reducers/api/node.api'
import Document from './Document/Document'
import Entities from './Entities'
import PasteDialog from './Entities/PasteDialog/PasteDialog'
import ResponsibleReference from './ResponsibleReference'
import TopPanel from './TopPanel'

function Content() {

  const { t } = useTranslation()
  const [section, nodeId] = usePath()
  const dispatch = useAppDispatch()

  const { currentData: favoritesData } = useGetFavoritesQuery(undefined, { skip: section !== 'favorites' })

  const showArchived = useAppSelector(state => state.settings.showDeleted)

  const { currentData: nodeData, error: nodeError } = useGetNodeQuery({
    id: nodeId, showArchived
  }, { skip: nodeId === undefined || section === 'document' })

  const { currentData: documentData, error: documentError } = useGetDocumentNodeQuery({ id: nodeId }, {
    skip: !nodeId || section !== 'document',
  })

  const node = (nodeId && (documentData ? { ...documentData, nodeType: 'DOCUMENT' } : false))
    || (nodeId && nodeData)
    || { name: t(`kbl.title.${section}`), children: { content: favoritesData } } as any

  const error = nodeError || documentError

  const confirmArchive = useConfirm()
  const { completed, upload, cut, copy, archive, restore, paste, onPasteDialogClose } = useClipboard(node)

  const [openPasteDialog, setOpenPasteDialog] = useState(false)

  const clipboard = useAppSelector(state => state.clipboard)

  const handlePasteDialogClose = () => {
    setOpenPasteDialog(false)
    setTimeout(() => onPasteDialogClose(), 300)
  }

  const handleUpload = async () => {
    setOpenPasteDialog(true)
    await paste()
  }

  const handlePaste = async () => {
    setOpenPasteDialog(true)
    await paste()
  }

  const handleArchive = async () => {
    await paste()
    onPasteDialogClose()
  }

  const handleRestore = async () => {
    await paste()
    onPasteDialogClose()
  }

  const handleRestoreClick = (nodes: NodeType[]) => {
    confirmArchive({
      title: t('kbl.dialog.title.general.confirm'),
      message: nodes.length === 1
        ? t('kbl.dialog.message.restoreNode', { nodeName: nodes[0].name })
        : t('kbl.dialog.message.restoreNodes'),
      onAccept: () => {
        restore(nodes)
      },
    })
  }

  const handleArchiveClick = (nodes: NodeType[]) => {
    confirmArchive({
      title: t('kbl.dialog.title.general.confirm'),
      acceptColor: 'error',
      message: nodes.length === 1
        ? t('kbl.dialog.message.archiveNode', { nodeName: nodes[0].name })
        : t('kbl.dialog.message.archiveNodes'),
      onAccept: () => {
        archive(nodes)
      },
    })
  }

  useEffect(() => {
    if (clipboard.op === 'upload')
      handleUpload()

    if (clipboard.op === 'archive')
      handleArchive()

    if (clipboard.op === 'restore')
      handleRestore()

  }, [clipboard.op])

  return (
    <>
      <Stack
        id='content'
        spacing='20px'
        sx={{
          overflow: 'auto',
          padding: '20px 20px 0 8px'
        }}
      >
        <TopPanel node={node} error={(nodeError as FetchBaseQueryError)?.status} />

        {!nodeError ? (
          <Routes>
            <Route path='/favorites/:id?' element={<Entities node={node} error={error} onArchive={handleArchiveClick} onPaste={handlePaste} onRestore={handleRestoreClick} />} />
            <Route path='/content/:id?' element={<Entities node={node} error={error} onArchive={handleArchiveClick} onPaste={handlePaste} onRestore={handleRestoreClick} />} />
            <Route path='/reference/:id' element={<ResponsibleReference node={node} />} />
            <Route path='/document/:id' element={<Document node={node} error={error} onArchive={handleArchiveClick} onRestore={handleRestoreClick} />} />
          </Routes>
        )
          : <Typography variant='h5'>Ресурс не найден</Typography>
        }

      </Stack>

      <PasteDialog
        title={PASTE_HEADER[clipboard.op]}
        open={openPasteDialog}
        completed={completed}
        onClose={handlePasteDialogClose}
      />
    </>
  )
}

export default Content
