import { Box, Typography } from '@mui/material'
import Tooltip from '@mui/material/Tooltip'
import NodeIcon from 'components/Icons/NodeIcon/NodeIcon'
import ContextMenu from 'components/ui/ContextMenu'
import FontIcon from 'components/ui/FontIcon/FontIcon'
import { MenuItemType } from 'components/ui/MenuList/MenuList'
import { NOOP } from 'config/constants'
import { useAppDispatch, useAppSelector } from 'config/store'
import useActions from 'hooks/useActions'
import NodeType from 'models/node.model'
import React, { ElementType, useRef, useState } from 'react'
import { setContextId } from 'reducers/context'
import { isEditable } from 'utils/checkRight'
import { OnSelectType } from 'view/types/node'
import NodeBadge from './NodeBadge/NodeBadge'
import NodePending from './NodePending/NodePending'
import NodeRenaming from './NodeRenaming/NodeRenaming'

interface NodeProps {
  node: NodeType
  component?: ElementType
  textOnly?: (options: any) => React.ReactNode
  contextId?: string
  selected?: boolean
  contextMenuHeader?: MenuItemType[]
  contextMenuItems?: MenuItemType[]
  icon?: React.ReactNode
  badge?: React.ReactNode
  onBeforeOpen?: () => Promise<void> | void
  onSelect?: OnSelectType
  selectedOne?: NodeType
}

const Node = (props: NodeProps) => {

  const { component = 'div',
    node,
    textOnly,
    selected,
    onSelect = NOOP,
    //onBeforeOpen = NOOP,
  } = props

  const dispatch = useAppDispatch()
  const renaming = useAppSelector((state) => state.node.renaming)

  const renamingRef = useRef<NodeType>(null)
  renamingRef.current = renaming

  const { getContextHeader, getContextItems, completeRenameNode, open } =
    useActions(node)

  const clipboard = useAppSelector((state) => state.clipboard)

  const [isTooltip, setIsTooltip] = useState(false)
  const [isContextMenu, setIsContextMenu] = useState(false)
  const [pending, setPending] = useState(false)

  const isCut =
    clipboard?.op === 'cut' &&
    clipboard?.data?.find((item) => item.id === node.id)
  const isDeleting =
    clipboard?.op === 'archive' &&
    clipboard?.data.find((item) => item.id === node.id)
  const isRestoring =
    clipboard?.op === 'restore' &&
    clipboard?.data.find((item) => item.id === node.id)
  const isPending = pending || isDeleting || isRestoring

  const confirmRenameNode = async (newName: string) => {
    setPending(true)
    await completeRenameNode(newName)
    setPending(false)
  }

  const handleSelect = (e: React.MouseEvent) => {
    e.stopPropagation()
    dispatch(setContextId(undefined))
    if (e.detail === 2) open()
    else onSelect([node], e)
  }

  const onBeforeOpen = async () => {
    // const promise = new Promise(resolve => {
    //   setTimeout(() => {
    //     resolve(true)
    //   }, 0)
    // })
    // await promise
  }

  const handleBeforeOpen = async () => {
    setPending(true)
    await onBeforeOpen()
    setPending(false)
  }

  return (
    <ContextMenu
      component={component}
      contextId={`${node.nodeType}-${node.id}`}
      onBeforeOpen={handleBeforeOpen}
      header={getContextHeader()}
      items={getContextItems()}
      onOpen={() => setIsContextMenu(true)}
      onClose={() => setIsContextMenu(false)}
    >
      <Tooltip
        open={isTooltip && !isContextMenu}
        placement='top'
        arrow
        disableHoverListener={isContextMenu}
        title={node.name}
        onOpen={() => setIsTooltip(true)}
        onClose={() => setIsTooltip(false)}
      >
        <Box
          sx={{
            ...(isPending && {
              opacity: '0.5',
              pointerEvents: 'none',
            }),
            ...(isCut && {
              opacity: '0.5',
            }),
          }}
        >
          {textOnly ? (
            <>{textOnly({ onRenamingConfirm: confirmRenameNode })}</>
          ) : (
            <Box
              onClick={handleSelect}
              sx={{
                position: 'relative',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: '8px',
                width: '110px',
                height: '115px',
                padding: '10px',
                outline: (theme) =>
                  selected ? `1px solid ${theme.palette.primary.main}` : 'none',
                ...(isContextMenu && {
                  backgroundColor: 'primary.main01',
                }),
                '&:hover': {
                  backgroundColor: 'primary.main01',
                  cursor: 'pointer',
                },
              }}
            >
              {!isEditable(node) && (
                <Box
                  sx={{
                    position: 'absolute',
                    zIndex: 1,
                    width: '100%',
                    top: '5px',
                    right: '2px',
                    textAlign: 'right',
                  }}
                >
                  <FontIcon icon='edit_off' color='disabled' />
                </Box>
              )}

              <Box
                sx={{
                  position: 'absolute',
                  zIndex: 1,
                  width: '100%',
                  display: 'flex',
                  top: '40px',
                  justifyContent: 'center',
                }}
              >
                <NodeBadge node={node} />
              </Box>

              {isPending && <NodePending />}

              <NodeIcon node={node} />

              {renaming?.id === node.id ? (
                <NodeRenaming
                  node={node}
                  renaming={renaming}
                  onConfirm={confirmRenameNode}
                />
              ) : (
                <Typography
                  sx={{
                    variant: 'subtitle1',
                    overflow: 'hidden',
                    wordWrap: 'break-word',
                    display: '-webkit-box',
                    lineHeight: 'normal',
                    WebkitLineClamp: '2',
                    WebkitBoxOrient: 'vertical',
                    textAlign: 'center',
                    width: '100px',
                  }}
                >
                  {node.name}
                </Typography>
              )}
            </Box>
          )}
        </Box>
      </Tooltip>
    </ContextMenu>
  )
}

export default Node
