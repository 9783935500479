import axios, { AxiosResponse } from "axios"
import { commonApi } from "config/common-api"
import { useAppDispatch, useAppSelector } from "config/store"
import NodeType from "models/node.model"
import { useState } from "react"
import { useCreateAttachmentMutation } from "reducers/api/attachment.api"
import { useArchiveNodeMutation, useCopyNodeMutation, useCreateDocumentNodeMutation, useMoveNodeMutation, useRestoreNodeMutation } from "reducers/api/node.api"
import { ClipboardItem, setClipboard } from "reducers/clipboard"
//import sortNodes from "utils/sortNodes"
import { useSort } from "./useSort"

const useClipboard = (node?: NodeType) => {

  const dispatch = useAppDispatch()
  const clipboard = useAppSelector(state => state.clipboard)

  const sortNodes = useSort()

  const [copyNode] = useCopyNodeMutation()
  const [moveNode] = useMoveNodeMutation()
  const [createDocumentNode] = useCreateDocumentNodeMutation()
  const [archiveNode] = useArchiveNodeMutation()
  const [restoreNode] = useRestoreNodeMutation()
  const [createAttachment] = useCreateAttachmentMutation()

  const [completed, setCompleted] = useState<{ [key: string]: boolean | string }>({})

  const handleClose = () => {
    setCompleted({})
    if (clipboard.op === 'cut' || clipboard.op === 'upload' || clipboard.op === 'archive' || clipboard.op === 'restore') {
      dispatch(setClipboard(undefined))
    }
  }

  const upload = (files: File[]) => {
    if (!node) return
    const data = files.map(item => {
      const filename = item.name.replace(/\.[^/.]+$/, "")
      return ({ id: filename, type: 'file', data: item })
    })
    dispatch(setClipboard({ op: 'upload', source: node, data }))
  }

  const copy = (nodes: NodeType[]) => {
    if (!node) return
    const data = sortNodes(nodes).map(item => ({ id: item.id, type: 'node', data: item }))
    dispatch(setClipboard({ op: 'copy', source: node, data }))
  }

  const cut = (nodes: NodeType[]) => {
    if (!node) return
    const data = sortNodes(nodes).map(item => ({ id: item.id, type: 'node', data: item }))
    dispatch(setClipboard({ op: 'cut', source: node, data }))
  }

  const archive = async (nodes: NodeType[]) => {
    if (!node) return
    const data = sortNodes(nodes).map(item => ({ id: item.id, type: 'node', data: item }))
    dispatch(setClipboard({ op: 'archive', source: node, data }))
  }

  const restore = async (nodes: NodeType[]) => {
    if (!node) return
    const data = sortNodes(nodes).map(item => ({ id: item.id, type: 'node', data: item }))
    dispatch(setClipboard({ op: 'restore', source: node, data }))
  }

  const putObject = async (url: string, file: File) => {
    return axios.put(url, file)
  }

  const createDocument = async (file: File) => {
    const presignedPutUrl = await createAttachment({ name: file.name })
    if ((presignedPutUrl as AxiosResponse<{ url: string, attachmentId: string }>)?.data) {
      const data = (presignedPutUrl as AxiosResponse<{ url: string, attachmentId: string }>).data
      const { url, attachmentId } = data
      const uploadFile = await putObject(url, file)
      if ((uploadFile as AxiosResponse<undefined>)?.status === 200) {
        return createDocumentNode({
          parent: { id: node?.id },
          name: file.name,
          nodeType: 'DOCUMENT',
          attachmentId
        })
      }
    }
  }

  const paste = async () => {
    let isError = false

    await Promise.all(clipboard.data?.map(async (item: ClipboardItem) => {
      let result: any
      switch (clipboard.op) {
        case 'copy':
          result = await copyNode({ nodeId: item.id, destinationId: node.id })
          break
        case 'cut':
          result = await moveNode({ nodeId: item.id, destinationId: node.id })
          break
        case 'upload':
          result = await createDocument(item.data as File)
          break
        case 'archive':
          result = await archiveNode({ id: item.id })
          break
        case 'restore':
          result = await restoreNode({ id: item.id })
          break
      }
      if ((result as any)?.error) {
        isError = true
        setCompleted(value => ({ ...value, [item.id]: (result as any).error.data?.message || 'Unknown error' }))
      }
      else {
        setCompleted(value => ({ ...value, [item.id]: true }))
      }
      return result
    }))
      .then(() => {
        setTimeout(() => dispatch(commonApi.util.invalidateTags(['NODE', 'QUICK_NODES', 'FAVORITES', 'TRENDS'])), 500)
      })
  }

  return { clipboard, completed, upload, cut, copy, archive, restore, paste, onPasteDialogClose: handleClose }
}

export default useClipboard
