import ContextMenu from "components/ui/ContextMenu"
import TreeSubItem from "components/ui/Tree/TreeSubItem"
import { TreeSubItemProps } from "components/ui/Tree/TreeSubItem/TreeSubItem"
import { CONTEXT_MENU_ITEMS, MANAGERS } from "config/constants"
import useActions from "hooks/useActions"
import { useMemo, useState } from "react"
import { useGetMeQuery } from "reducers/api/employee.api"
import { isEditable } from "utils/checkRight"

function SidebarTreeSubItem(props: TreeSubItemProps) {

  const { data: meData } = useGetMeQuery()

  const { node: item, selected: initialSelected, ...rest } = props

  const { renderItems } = useActions(item)

  const [selected, setSelected] = useState(false)

  const contextItems = useMemo(() => [
    CONTEXT_MENU_ITEMS.HOMEPAGE,
    CONTEXT_MENU_ITEMS.FAVORITE,
    CONTEXT_MENU_ITEMS.QUICK_NODE,
    ...(MANAGERS.includes(meData?.role.recordType) ? [CONTEXT_MENU_ITEMS.MANAGE_TRENDS] : []),
    ...(isEditable(item) ? [CONTEXT_MENU_ITEMS.RESPONSIBLE, CONTEXT_MENU_ITEMS.NODE_RIGHTS] : []),
  ], [item])

  return (
    <ContextMenu
      contextId={`sidebar-subitem-${item.id}`}
      items={renderItems(contextItems)}
      onOpen={() => { setSelected(true) }}
      onClose={() => { setSelected(false) }}
    >
      <TreeSubItem node={item} selected={selected || initialSelected} {...rest} />
    </ContextMenu>
  )
}

export default SidebarTreeSubItem
